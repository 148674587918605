<template>

    <div class="h-auto rounded-lg flex flex-col overflow-hidden bg-filters relative">
        <loader :loading="loading" />
        <div class="h-auto py-3 flex flex-row justify-between items-center px-2 mb-auto">
            <span class="text-font-gray font-semibold text-xs">Rate</span>
        </div>
        <div v-if="rateData" class="flex justify-between items-center mx-2 mb-4">
            <div>
                <div class="text-font-gray text-xxs mt-1">Average Rate</div>
                <div class="text-sm font-bold mt-1">{{rateData.average_rate | numberFormat(2)}}€/h</div>
            </div>
            <div>
                <div class="text-font-gray text-xxs mt-1">vs Future </div>
                <div class="text-sm font-bold mt-1" :class="{'text-good': rateData.vs_average_rate > 0, 'text-danger': rateData.vs_average_rate <= 0}">{{rateData.vs_average_rate | numberFormat(2)}} ptos.</div>
            </div>
            <div>
                <div class="text-font-gray text-xxs mt-1">Future Average Rate</div>
                <div class="text-sm font-bold mt-1">{{rateData.future_average_rate | numberFormat(2)}}€/h</div>
            </div>
        </div>
    </div>

</template>

<script>

    import { state } from '@/store';

    export default {
        data() {
            return {
                loading: false,
                rateData: false
            }
        },
        methods:{
            preload() {
                this.loading = true
            },
            load() {
                this.axios.get('projects_management/ner', {params: this.params}).then(response => {
                    this.loading = false;
                    this.rateData = response.data.data;
                })
            }
        },
        computed: {
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{sectorAmbiciona: this.$route.params.catId} }
                }
                return params
            }
        },
        watch: {
            params() { this.load() }
        },
        mounted() {
            this.preload();
            if(this.params) {
                this.load();
            }
        }
    }

</script>